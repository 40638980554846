<template>
  <v-dialog v-model="dialogShow" width="800px" content-class="set-composition-modal customEditModal">
    <v-card class="pa-5">
      <h1>REGISTER FABRIC COMPOSITION</h1>

      <div class="mt-10 content">
        <v-row v-for="(composition, index) in compositions" :key="index">
          <div class="d-flex info-select">
            <v-autocomplete
              dense
              solo
              hide-details
              flat
              outlined
              :items="$store.state.code.common.fabricComposition"
              item-value="itemName"
              item-text="itemName"
              label="Select Fabric Composition"
              v-model="composition.mtrlName">
              <template v-slot:append>
                <v-icon> mdi-chevron-down </v-icon>
              </template>
            </v-autocomplete>
          </div>

          <div class="info-input d-flex">
            <v-text-field
              dense
              outlined
              hide-details
              background-color="white"
              type="number"
              append-icon="mdi-percent"
              v-model.number="composition.mtrlRate" />

            <v-btn color="secondary" outlined fab small @click="compositions.splice(index, 1)">
              <v-icon> mdi-minus </v-icon>
            </v-btn>
          </div>
        </v-row>
        <v-row>
          <v-btn color="secondary" outlined @click="addComposition"> ADD FABRIC COMPOSITION </v-btn>
        </v-row>
      </div>

      <div class="actions">
        <v-btn color="secondary" dark :elevation="0" @click="save"> SAVE </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { calc, hasValue, isDuplicate, isNumber, _deepCopy } from "@/utils/util";

export default {
  props: ["show", "items"],
  data() {
    return {
      compositions: [],
    };
  },
  methods: {
    save() {
      if (this.compositions?.length < 1) return this.$toast.warning("There must be at least one");

      let sum = 0;
      for (let i = 0; i < this.compositions.length; i++) {
        const composition = this.compositions[i];

        if (![composition.mtrlName, composition.mtrlRate].every(hasValue))
          return this.$toast.warning("All values are required inputs");

        if (!isNumber(composition.mtrlRate)) return this.$toast.warning("The Rate can only be entered with numbers");

        if (composition.mtrlRate === 0) return this.$toast.warning("The Rate must be more than 0");

        sum += composition.mtrlRate;
      }

      if (isDuplicate(this.compositions.map(i => i.mtrlName)))
        return this.$toast.warning("Fabric Composition cannot be duplicated.");

      if (sum !== 100) return this.$toast.warning("The sum of rate must be 100");

      this.$emit("setComposition", this.compositions);
      this.dialogShow = false;
    },
    addComposition() {
      const max = 100;

      let sum = 0;
      this.compositions.forEach(c => (sum += c.mtrlRate));

      if (isNumber(sum)) this.compositions.push({ mtrlName: "", mtrlRate: calc(max - parseFloat(sum)) });
      else this.compositions.push({ mtrlName: "", mtrlRate: 0 });
    },
    setCompositions() {
      //props가 Array이며 값이 있을 경우, immutable하게 compositions에 세팅
      if (hasValue(this.items) && Array.isArray(this.items) && this.items[0].mtrlName != "") {
        this.compositions = _deepCopy(this.items);
      } else {
        this.addComposition();
      }
    },
  },
  computed: {
    dialogShow: {
      get() {
        return this.show;
      },
      set(value) {
        Object.assign(this.$data, this.$options.data.apply(this));
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show(newVal) {
      if (newVal)
        //해당 모달이 보여지는 상태로 변경 되었을 때만
        this.setCompositions();
    },
  },
};
</script>

<style></style>
