<template>
  <v-dialog v-model="dialogShow" width="800px" content-class="create-company-modal customEditModal">
    <v-card class="pa-5">
      <h1>REGISTER NEW COMPANY</h1>

      <div class="mt-10 content">
        <v-row v-for="(info, index) in companyInfoList.filter(c => c.show !== false)" :key="index">
          <div class="d-flex info-label">
            <h3>
              <span :class="info.essential ? 'essential' : ''">{{ info.text }} </span>
            </h3>
          </div>
          <div class="info-input">
            <v-text-field
              v-if="
                ['name', 'businessNumber', 'contactName', 'contactMail', 'address', 'postal'].some(i => info.key === i)
              "
              outlined
              single-line
              dense
              :label="`Insert ${info.text}`"
              :rules="[
                rules[info.essential ? 'required' : 'none'],
                info.rules !== '' ? rules[info.rules] : rules['none'],
              ]"
              v-model="$data[info.key]"></v-text-field>

            <v-autocomplete
              v-else-if="info.key === 'cmpyType'"
              dense
              solo
              hide-details
              flat
              outlined
              :disabled="spplrFixed !== undefined || manufactureFixed !== undefined"
              :items="$store.state.code.common.companyType"
              item-text="itemName"
              item-value="itemCd"
              :label="`Select ${info.text}`"
              v-model="cmpyType">
              <template v-slot:append>
                <v-icon> mdi-chevron-down </v-icon>
              </template>
            </v-autocomplete>

            <div v-else-if="info.key === 'businessLicense'">
              <input type="file" ref="businessLicense" style="display: none" accept="image/*" @change="changeLicense" />
              <v-btn outlined color="secondary" @click="$refs.businessLicense[0].click()"> UPLOAD IMAGE </v-btn>
              <span class="fileName">{{ fileName }}</span>
            </div>

            <div class="d-flex call" v-else-if="info.key === 'contactCall'">
              <div style="width: 200px">
                <v-autocomplete
                  dense
                  solo
                  hide-details
                  flat
                  outlined
                  :items="countries"
                  v-model="contactCallCountry"
                  :label="`Select Country`">
                  <template v-slot:append>
                    <v-icon> mdi-chevron-down </v-icon>
                  </template>
                </v-autocomplete>
              </div>

              <v-text-field
                outlined
                single-line
                dense
                :label="`Insert ${info.text}`"
                :rules="[rules.required]"
                class="ml-4"
                v-model="contactCall"></v-text-field>
            </div>

            <v-autocomplete
              v-else-if="info.key === 'countryCode'"
              dense
              solo
              hide-details
              flat
              outlined
              :items="$store.state.code.common.country"
              item-text="itemCd"
              item-value="itemCd"
              :label="`Select ${info.text}`"
              v-model="countryCode">
              <template v-slot:append>
                <v-icon> mdi-chevron-down </v-icon>
              </template>
            </v-autocomplete>

            <v-autocomplete
              v-else-if="info.key === 'cityCode'"
              dense
              solo
              hide-details
              flat
              outlined
              :items="filteredCityCodes"
              item-text="itemName"
              item-value="itemName"
              :label="`Select ${info.text}`"
              v-model="cityCode"
              no-data-text="Please choose the country first">
              <template v-slot:append>
                <v-icon> mdi-chevron-down </v-icon>
              </template>
            </v-autocomplete>

            <div class="d-flex utc" v-else-if="info.key === 'gmtCountry'">
              <div>
                <v-autocomplete
                  dense
                  solo
                  hide-details
                  flat
                  outlined
                  item-text="itemCd"
                  item-value="itemCd"
                  :items="$store.state.code.common.countryUTC"
                  v-model="gmtCountry"
                  :label="`Select Country`"
                  @change="
                    () => {
                      gmtCity = '';
                      gmtValue = '';
                    }
                  ">
                  <template v-slot:append>
                    <v-icon> mdi-chevron-down </v-icon>
                  </template>
                </v-autocomplete>
              </div>
              <div class="ml-4">
                <v-autocomplete
                  dense
                  solo
                  hide-details
                  flat
                  outlined
                  :item-text="item => item.itemCd + ' (' + item.itemDesc + ')'"
                  item-value="itemCd"
                  :items="$store.state.code.common.countryCityUTC.filter(c => c.upperClass === gmtCountry)"
                  v-model="gmtCity"
                  @change="
                    val => {
                      gmtValue = $store.state.code.common.countryCityUTC
                        .filter(c => c.upperClass === gmtCountry)
                        .find(i => i.itemCd === val).itemDesc;
                    }
                  "
                  label="Select City"
                  no-data-text="Please choose the country first">
                  <template v-slot:append>
                    <v-icon> mdi-chevron-down </v-icon>
                  </template>
                </v-autocomplete>
              </div>
            </div>
          </div>
        </v-row>
      </div>

      <div class="actions">
        <v-btn color="secondary" dark :elevation="0" @click="regist"> REGISTER </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { hasValue } from "@/utils/util";
import codeHandler from "@/utils/codeHandler";
import messageHandler from "@/utils/messageHandler";
export default {
  props: ["show", "spplrFixed", "manufactureFixed"],
  data() {
    return {
      rules: {
        none: () => true,
        required: value => !!value || "Required",
        email: v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "It's not email format.",
      },
      companyInfoList: [
        { text: "Company Name", key: "name", essential: true, rules: "" },
        {
          text: "Main Business Type",
          key: "cmpyType",
          essential: true,
          rules: "",
        },
        {
          text: "Business Number",
          key: "businessNumber",
          essential: false,
          rules: "",
        },
        {
          text: "Business License",
          key: "businessLicense",
          essential: false,
          rules: "",
        },
        {
          text: "Contact Member Name",
          key: "contactName",
          essential: true,
          rules: "",
        },
        {
          text: "Contact Call Country",
          key: "contactCallCountry",
          essential: true,
          rules: "",
          show: false,
        },
        {
          text: "Contact Call Number",
          key: "contactCall",
          essential: true,
          rules: "",
        },
        {
          text: "Contact E-mail",
          key: "contactMail",
          essential: true,
          rules: "email",
        },
        { text: "Country", key: "countryCode", essential: true, rules: "" },
        { text: "Address", key: "address", essential: true, rules: "" },
        { text: "City", key: "cityCode", essential: true, rules: "" },
        { text: "Postal Code", key: "postal", essential: true, rules: "" },
        { text: "UTC", key: "gmtCountry", essential: true, rules: "" },
        { text: "gmtCity", key: "gmtCity", essential: true, rules: "", show: false },
        { text: "gmtValue", key: "gmtValue", rules: "", show: false },
      ],
      name: "",
      cmpyType: "",
      businessNumber: "",
      businessLicense: null,
      contactName: "",
      contactCall: "",
      contactCallCountry: "",
      contactMail: "",
      countryCode: "",
      address: "",
      cityCode: "",
      postal: "",
      gmtCountry: "",
      gmtCity: "",
      gmtValue: "",
      fileName: "",
    };
  },
  computed: {
    dialogShow: {
      get() {
        return this.show;
      },
      set(value) {
        // Object.assign(this.$data, this.$options.data.apply(this));
        if (this.spplrFixed) this.cmpyType = "SUPPLIER";
        if (this.manufactureFixed) this.cmpyType = "MANUFACTURER";
        this.$emit("update:show", value);
      },
    },
    filteredCityCodes() {
      return this.$store.state.code.common.country.find(c => c.itemCd === this.countryCode)?.city;
    },
    countries() {
      return this.$store.state.code.common.country
        ? this.$store.state.code.common.country.map(c => ({
            text: `${c.itemCd} (${c.itemName})`,
            value: c.itemName,
          }))
        : [];
    },
  },
  methods: {
    regist() {
      if (
        !this.companyInfoList
          .filter(i => i.essential)
          .map(i => this[i.key])
          .every(hasValue) ||
        this.rules.email(this.contactMail) !== true
      )
        return messageHandler("required");

      const params = {};
      this.companyInfoList.forEach(item => {
        params[item.key] = this[item.key];
      });

      let url = "";
      if (this.spplrFixed) url = "/material/supplier";
      else if (this.manufactureFixed) url = "/mcl/manufacturer";
      else url = "/company";

      this.$http.post(url, params).then(res => {
        if (res.data.status === 200) {
          this.uploadLicense(res.data.data.cmpyUUID);
          this.$toast.success("Successfully Registered");
          this.$emit("regist", res.data.data);
          this.dialogShow = false;
        } else codeHandler(res.data);
      });
    },
    uploadLicense(uuid) {
      if (this.$refs.businessLicense[0].files.length < 1 || !this.$refs.businessLicense[0].files[0] || !uuid) return;

      let form = new FormData();
      form.append("file", this.$refs.businessLicense[0].files[0]);

      this.$http
        .post(`/company/${uuid}`, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(res => {
          if (res.data.status !== 200) this.$toast.error("Image Upload Failed.");
        });
    },
    changeLicense() {
      if (this.$refs.businessLicense[0].files.length < 1 || !this.$refs.businessLicense[0].files[0]) return;
      this.fileName = this.$refs.businessLicense[0].files[0].name;
    },
  },
  mounted() {
    if (this.spplrFixed) this.cmpyType = "SUPPLIER";
    if (this.manufactureFixed) this.cmpyType = "MANUFACTURER";
  },
};
</script>

<style></style>
