<template>
  <v-dialog v-model="dialogShow" width="800px" content-class="request-price-modal customEditModal">
    <v-card class="pa-5">
      <h1>REQUEST PRICE</h1>

      <div class="mt-10 content">
        <div class="d-flex requestor-info">
          <div class="font-blue1">
            {{ userName }}
          </div>
          <v-spacer />
          <div>
            {{ companyName }}
          </div>
        </div>

        <div class="message">
          <v-textarea v-if="isSendMode" outlined placeholder="Leave your Request notes" v-model="message" />
          <div v-else>{{ msg }}</div>
        </div>
      </div>

      <div class="actions">
        <v-btn v-if="isSendMode" color="secondary" dark :elevation="0" :loading="sendLoading" @click="send">
          SEND
        </v-btn>
        <v-btn
          v-if="canSuggestion === true"
          color="secondary"
          dark
          :elevation="0"
          :loading="suggestionLoading"
          @click="suggestion">
          SUGGESTION
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { hasValue } from "@/utils/util";
import codeHandler from "@/utils/codeHandler";
export default {
  props: ["show", "mtrlUUID", "mode", "canSuggestion", "msg", "userName", "companyName"],
  data() {
    return {
      message: "",
      sendLoading: false,
      suggestionLoading: false,
    };
  },
  methods: {
    send() {
      if (!hasValue(this.message)) return this.$toast.warning("Please enter the message.");

      this.sendLoading = true;
      this.$http
        .post("/material/cost/request", {
          mtrlUUID: this.mtrlUUID,
          cmpyUUID: this.$store.getters.getCmpyUUID,
          message: this.message,
        })
        .then(res => {
          if (res.data.status === 200) {
            this.$emit("send", {
              inputLocation: "R",
              workedBy: this.userName,
              regUserName: this.userName,
              regCmpyName: this.companyName,
              costHistId: res.data.data.costHistId,
              when: res.data.data.localWhen,
              message: this.message,
            });
            this.dialogShow = false;
          } else codeHandler(res.data);
        })
        .finally(() => (this.sendLoading = false));
    },
    suggestion() {
      this.$emit("suggestion");
    },
  },
  computed: {
    dialogShow: {
      get() {
        return this.show;
      },
      set(value) {
        Object.assign(this.$data, this.$options.data.apply(this));
        this.$emit("update:show", value);
      },
    },
    isSendMode() {
      return this.mode === "send";
    },
  },
};
</script>

<style></style>
