var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cost-record-history"},[_c('div',{staticClass:"d-flex"},[_c('h2',[_vm._v("COST RECORD HISTORY")]),_c('v-spacer'),(_vm.material.spplrUUID === _vm.cmpyUUID)?_c('v-btn',{attrs:{"outlined":"","color":"secondary"},on:{"click":function($event){_vm.ofcShow = true}}},[_vm._v(" INPUT PRICE ")]):_c('v-btn',{attrs:{"outlined":"","color":"secondary"},on:{"click":_vm.requestPrice}},[_vm._v(" REQUEST PRICE")])],1),_c('v-divider'),_c('v-data-table',{attrs:{"hide-default-footer":"","disable-sort":"","headers":_vm.headers,"items":_vm.material.materialCostHistoryDtoList,"page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.mmlTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s([item.moq, item.mcq, item.leadtime].every(_vm.hasValue) ? ((item.moq) + " / " + (item.mcq) + " / " + (item.leadtime) + " days") : "")+" ")]}},{key:"item.fwCw",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s([item.fw, item.cw].every(_vm.hasValue) ? ((item.fw) + "/" + (item.cw) + "(inch)") : "")+" ")]}},{key:"item.yearSeason",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s([item.year, item.season].every(_vm.hasValue) ? ((item.year) + " / " + (item.season)) : "")+" ")]}},{key:"item.inputLocation",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getInputLocationInfo(item).html)},on:{"click":function($event){_vm.getInputLocationInfo(item).event(item)}}})]}},{key:"item.workedBy",fn:function(ref){
var item = ref.item;
return [(_vm.hasValue(item.cmpyUUID))?_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hoverUnderline pointer"},'span',attrs,false),on),[_vm._v(_vm._s(item.workedBy))])]}}],null,true),model:{value:(item.menu),callback:function ($$v) {_vm.$set(item, "menu", $$v)},expression:"item.menu"}},[_c('company-info',{attrs:{"cmpyUUID":item.cmpyUUID}})],1)],1):_c('div',{staticClass:"font-blue1"},[_vm._v(_vm._s(item.workedBy))])]}},{key:"item.localWhen",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$m(item.localWhen).format("yyyy-MM-DD HH:mm"))+" ")]}}],null,true)},[_vm._v(" > "),_c('template',{slot:"no-data"},[_vm._v(" NO DATA "),_c('br'),_vm._v(" Press the REQUEST PRICE button at the top right. "),_c('br'),_vm._v(" Please check the Amount "),_c('br'),_vm._v(" Or "),_c('br'),_vm._v(" Please Contact the supplier by phone or e-mail ")])],2),(_vm.pageCount > 1)?_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"color":"secondary","total-visible":_vm.$pagePV},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e(),_c('request-price',{attrs:{"show":_vm.rpShow,"mode":_vm.rpMode,"mtrlUUID":_vm.material.mtrlUUID,"msg":_vm.rpMsg,"userName":_vm.rpUserName,"companyName":_vm.rpCompanyName,"canSuggestion":_vm.material.spplrUUID === _vm.cmpyUUID},on:{"update:show":function($event){_vm.rpShow=$event},"send":_vm.sendRequestPrice,"suggestion":_vm.suggestion}}),_c('save-price',{attrs:{"mode":"suggestion","show":_vm.sugShow,"mtrlUUID":_vm.material.mtrlUUID,"parentCostHistId":_vm.sugParentCostHistId},on:{"update:show":function($event){_vm.sugShow=$event},"save":_vm.savedPrice}}),_c('save-price',{attrs:{"mode":"view","show":_vm.sugViewShow,"priceInfo":_vm.sugViewPriceInfo},on:{"update:show":function($event){_vm.sugViewShow=$event}}}),_c('save-price',{attrs:{"mode":"official","show":_vm.ofcShow,"mtrlUUID":_vm.material.mtrlUUID,"parentCostHistId":0},on:{"update:show":function($event){_vm.ofcShow=$event},"save":_vm.savedPrice}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }