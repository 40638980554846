<template>
  <div class="cost-record-history">
    <div class="d-flex">
      <h2>COST RECORD HISTORY</h2>
      <v-spacer></v-spacer>
      <v-btn outlined color="secondary" v-if="material.spplrUUID === cmpyUUID" @click="ofcShow = true">
        INPUT PRICE
      </v-btn>
      <v-btn outlined color="secondary" v-else @click="requestPrice"> REQUEST PRICE</v-btn>
    </div>
    <v-divider />
    <v-data-table
      hide-default-footer
      disable-sort
      :headers="headers"
      :items="material.materialCostHistoryDtoList"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event">
      >

      <template slot="no-data">
        NO DATA
        <br />
        Press the REQUEST PRICE button at the top right.
        <br />
        Please check the Amount
        <br />
        Or
        <br />
        Please Contact the supplier by phone or e-mail
      </template>

      <!-- mmlTime -->
      <template v-slot:[`item.mmlTime`]="{ item }">
        {{
          [item.moq, item.mcq, item.leadtime].every(hasValue) ? `${item.moq} / ${item.mcq} / ${item.leadtime} days` : ""
        }}
      </template>

      <!-- fwCw -->
      <template v-slot:[`item.fwCw`]="{ item }">
        {{ [item.fw, item.cw].every(hasValue) ? `${item.fw}/${item.cw}(inch)` : "" }}
      </template>

      <!-- yearSeason -->
      <template v-slot:[`item.yearSeason`]="{ item }">
        {{ [item.year, item.season].every(hasValue) ? `${item.year} / ${item.season}` : "" }}
      </template>

      <!-- inputLocation -->
      <template v-slot:[`item.inputLocation`]="{ item }">
        <div @click="getInputLocationInfo(item).event(item)" v-html="getInputLocationInfo(item).html" />
      </template>

      <!-- workedBy -->
      <template v-slot:[`item.workedBy`]="{ item }">
        <div v-if="hasValue(item.cmpyUUID)">
          <v-menu v-model="item.menu" :close-on-content-click="false" :nudge-width="200" offset-x>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="hoverUnderline pointer">{{ item.workedBy }}</span>
            </template>

            <company-info :cmpyUUID="item.cmpyUUID" />
          </v-menu>
        </div>

        <div v-else class="font-blue1">{{ item.workedBy }}</div>
      </template>

      <!-- localWhen -->
      <template v-slot:[`item.localWhen`]="{ item }">
        {{ $m(item.localWhen).format("yyyy-MM-DD HH:mm") }}
      </template>
    </v-data-table>

    <div class="text-center pt-2" v-if="pageCount > 1">
      <v-pagination v-model="page" :length="pageCount" color="secondary" :total-visible="$pagePV"></v-pagination>
    </div>

    <request-price
      :show.sync="rpShow"
      :mode="rpMode"
      :mtrlUUID="material.mtrlUUID"
      :msg="rpMsg"
      :userName="rpUserName"
      :companyName="rpCompanyName"
      :canSuggestion="material.spplrUUID === cmpyUUID"
      @send="sendRequestPrice"
      @suggestion="suggestion" />

    <save-price
      mode="suggestion"
      :show.sync="sugShow"
      :mtrlUUID="material.mtrlUUID"
      :parentCostHistId="sugParentCostHistId"
      @save="savedPrice" />

    <save-price mode="view" :show.sync="sugViewShow" :priceInfo="sugViewPriceInfo" />

    <save-price
      mode="official"
      :show.sync="ofcShow"
      :mtrlUUID="material.mtrlUUID"
      :parentCostHistId="0"
      @save="savedPrice" />
  </div>
</template>

<script>
import { hasValue } from "@/utils/util";
import CompanyInfo from "@/components/common/popover/CompanyInfo.vue";
import RequestPrice from "@/components/company/material/RequestPrice.vue";
import SavePrice from "@/components/company/material/SavePrice.vue";

export default {
  props: ["material"],
  components: {
    CompanyInfo,
    RequestPrice,
    SavePrice,
  },
  data() {
    return {
      headers: [
        { text: "CURRENCY", value: "currency" },
        { text: "UNIT PRICE", value: "unitPrice" },
        { text: "UOM", value: "uom" },
        { text: "FW/CW(UOM)", value: "fwCw" },
        { text: "MOQ/MCQ/LEAD TIME", value: "mmlTime" },
        { text: "OFFER BRAND", value: "offerBrand" },
        { text: "YEAR/SEASON", value: "yearSeason" },
        { text: "STYLE #", value: "styleNo" },
        { text: "INPUT LOCATION", value: "inputLocation" },
        { text: "WORKED BY", value: "workedBy" },
        { text: "WHEN", value: "localWhen" },
      ],
      page: 1,
      itemsPerPage: 10,
      pageCount: 0,
      rpShow: false,
      rpMode: "",
      rpMsg: "",
      rpUserName: "",
      rpCompanyName: "",
      sugShow: false,
      sugParentCostHistId: "",
      ofcShow: false,
      sugViewShow: false,
      sugViewPriceInfo: {},
    };
  },
  methods: {
    getInputLocationInfo({ inputLocation, linkUUID }) {
      switch (inputLocation) {
        case "R":
          return { html: "<span class='pointer underline'>REQUEST PRICE</span>", event: this.viewRequestPrice };
        case "S":
          return { html: "<span class='pointer underline'>SUGGESTION PRICE</span>", event: this.viewSuggestionPrice };
        case "O":
          return { html: "<span>OFFICIAL PRICE</span>", event: () => {} };
        case "PO":
          return {
            html: `<span class='PO'>PO</span> <span class='font-error pointer'>#${linkUUID}</span>`,
            event: ({ linkUUID }) => {
              const link = this.$router.resolve({
                name: "issuedRMPODetail",
                params: {
                  poUUID: linkUUID,
                },
              }).href;
              window.open(link, "_blank");
            },
          };
        case "CBD":
          return {
            html: `<span class='CBD'>CBD</span> <span class='font-error pointer'>#${linkUUID}</span>`,
            event: ({ linkUUID }) => {
              const link = this.$router.resolve({
                name: "CBD",
                params: {
                  cbdUUID: linkUUID,
                },
              }).href;
              window.open(link, "_blank");
            },
          };
        case "MCL":
          return {
            html: `<span class='MCL'>MCL</span> <span class='font-error pointer'>#${linkUUID}</span>`,
            event: ({ linkUUID }) => {
              const link = this.$router.resolve({
                name: "MCL",
                params: {
                  mclUUID: linkUUID,
                },
              }).href;
              window.open(link, "_blank");
            },
          };
        default:
          break;
      }
    },
    requestPrice() {
      this.rpMode = "send";
      this.rpUserName = this.$store.state.user.name;
      this.rpCompanyName = this.$store.getters.getCmpyName;
      this.rpShow = true;
    },
    viewRequestPrice({ costHistId, message, regUserName, regCmpyName }) {
      this.sugParentCostHistId = costHistId;
      this.rpMode = "view";
      this.rpMsg = message;
      this.rpUserName = regUserName;
      this.rpCompanyName = regCmpyName;
      this.rpShow = true;
    },
    sendRequestPrice(reqPriceInfo) {
      reqPriceInfo.itemNo = this.material.itemNo;
      this.material.materialCostHistoryDtoList.unshift(reqPriceInfo);
    },
    suggestion() {
      this.rpShow = false;
      this.sugShow = true;
    },
    viewSuggestionPrice(priceInfo) {
      this.sugViewPriceInfo = priceInfo;
      this.sugViewShow = true;
    },
    savedPrice(priceInfo) {
      this.material.materialCostHistoryDtoList.unshift(priceInfo);
    },
    hasValue,
  },
  computed: {
    cmpyUUID() {
      return this.$store.getters.getCmpyUUID;
    },
  },
};
</script>

<style></style>
