<template>
  <div class="material">
    <div class="page-info">
      <div class="page-title">
        <p class="title-1">Material Management</p>
      </div>

      <v-breadcrumbs :items="breadcrumbs">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>

    <v-card outlined flat rounded="xl" class="pa-8">
      <div class="d-flex gutter-mx-3 search-toolbar">
        <!-- Editable Select -->
        <template v-if="!editMode">
          <div class="editable-filter">
            <v-autocomplete dense solo hide-details flat outlined :items="editModes" v-model="editValue">
              <template v-slot:append>
                <v-icon> mdi-chevron-down </v-icon>
              </template>
            </v-autocomplete>
          </div>

          <div class="column-filter">
            <v-autocomplete dense solo hide-details flat outlined :items="searchColumns" v-model="filterColumn">
              <template v-slot:append>
                <v-icon> mdi-chevron-down </v-icon>
              </template>
            </v-autocomplete>
          </div>

          <!-- Column Search -->
          <div class="search">
            <v-text-field
              dense
              outlined
              hide-details
              v-model="searchText"
              placeholder="Insert Keyword to search"
              background-color="white"
              @keyup.enter="getMaterials(true)"></v-text-field>
          </div>

          <v-btn color="secondary" icon @click="getMaterials(true)" style="margin: 0; align-self: center">
            <v-icon> mdi-magnify </v-icon>
          </v-btn>
        </template>

        <v-spacer />

        <template v-if="!editMode">
          <v-btn color="secondary" dark :elevation="0" @click="changeEditMode(true)">REGISTER & MODIFY</v-btn>
        </template>
        <template v-else>
          <v-btn color="secondary" dark :elevation="0" :loading="saveLoading" @click="save">SAVE</v-btn>
          <v-btn color="secondary" dark :elevation="0" @click="changeEditMode(false)">CANCEL</v-btn>
        </template>
      </div>

      <!-- #region custom-table -->
      <div class="custom-table">
        <table>
          <thead>
            <tr>
              <th rowspan="2" class="left check">No</th>
              <th colspan="9">BASIC INFORMATION</th>
              <th colspan="5">DETAILED INFORMATION</th>
              <th rowspan="2" class="td-btn">{{ editMode ? "MORE DATA" : "EDIT" }}</th>
              <th v-if="editMode" rowspan="2" class="right td-btn"></th>
            </tr>
            <tr>
              <th :class="{ essential: editMode, left: true }">SUPPLIER</th>
              <th>Vgo ID</th>
              <th>MILL/ITEM #</th>
              <th>RD #</th>
              <th :class="{ essential: editMode }">COO</th>
              <th :class="{ essential: editMode }">MATERIAL<br />CATEGORY</th>
              <th :class="{ essential: editMode }">MATERIAL<br />TYPE</th>
              <th :class="{ essential: editMode }">ITEM<br />NAME</th>
              <th>COMPOSITION/<br />ITEM DETAIL</th>
              <th class="left">POST<br />PROCESSING</th>
              <th>DYEING</th>
              <th>PRINTING</th>
              <th>WEIGHT</th>
              <th class="right">ITEM SIZE</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="editMode">
              <td colspan="100" class="register pointer" @click="addMaterial">
                <v-btn x-small color="secondary" :elevation="0">REGISTER</v-btn> A NEW MATERIAL
              </td>
            </tr>
            <template v-for="(material, materialIndex) in materials">
              <tr
                :key="'material tr ' + materialIndex"
                @click="
                  () => {
                    if (!editMode) material.expanded = !material.expanded;
                  }
                ">
                <td>
                  {{ totalCnt - materialIndex - (page - 1) * itemsPerPage }}
                </td>
                <!-- SUPPLIER -->
                <td>
                  <!-- editMode 상관 없이, 생성된 이후로는 수정 불가 -->
                  <div v-if="hasValue(material.mtrlUUID)">
                    <v-menu v-model="material.menu" :close-on-content-click="false" :nudge-width="200" offset-x>
                      <template v-slot:activator="{ on, attrs }">
                        <div class="pointer hoverUnderline" v-bind="attrs" v-on="on">
                          {{ material.spplrName }}
                        </div>
                      </template>

                      <company-info :cmpy="material.companySelectDto" />
                    </v-menu>
                  </div>

                  <div v-else-if="material.canEdit === 'Y'" class="pointer underline">
                    <v-autocomplete
                      dense
                      solo
                      hide-details
                      flat
                      outlined
                      :items="spplrList"
                      item-text="name"
                      item-value="cmpyUUID"
                      v-model="material.spplrUUID"
                      @change="changeSpplrUUID(material)">
                      <template v-slot:label>
                        <span class="font-error">SET SUPPLIER</span>
                      </template>

                      <template v-slot:append>
                        <v-icon> mdi-chevron-down </v-icon>
                      </template>
                    </v-autocomplete>
                  </div>
                </td>
                <!-- Vgo ID -->
                <td>
                  <!-- 수정불가 -->
                  {{ material.vgoId }}
                </td>
                <!-- MILL/ITEM NO -->
                <td>
                  <v-text-field
                    v-if="editMode && material.canEdit === 'Y'"
                    dense
                    outlined
                    hide-details
                    v-model="material.itemNo"
                    background-color="white" />

                  <div v-else class="text-start">
                    {{ material.itemNo }}
                  </div>
                </td>
                <!-- RD NO -->
                <td>
                  <v-text-field
                    v-if="editMode && material.canEdit === 'Y'"
                    dense
                    outlined
                    hide-details
                    v-model="material.rdNo"
                    background-color="white" />

                  <div v-else class="text-start">
                    {{ material.rdNo }}
                  </div>
                </td>
                <!-- COO -->
                <td>
                  <v-autocomplete
                    v-if="editMode && material.canEdit === 'Y'"
                    dense
                    solo
                    hide-details
                    flat
                    outlined
                    :items="$store.state.code.common.country"
                    item-value="itemCd"
                    item-text="itemCd"
                    v-model="material.coo">
                    <template v-slot:label>
                      <span class="font-error">Required</span>
                    </template>
                    <template v-slot:append>
                      <v-icon> mdi-chevron-down </v-icon>
                    </template>
                  </v-autocomplete>
                  <div v-else class="text-start">
                    {{ material.coo }}
                  </div>
                </td>
                <!-- MATERIAL CATEGORY -->
                <td>
                  <v-autocomplete
                    v-if="!hasValue(material.mtrlUUID)"
                    dense
                    solo
                    hide-details
                    flat
                    outlined
                    :items="$store.state.code.common.materialCategory"
                    item-value="itemName"
                    item-text="itemName"
                    v-model="material.mtrlCate"
                    @change="changeMtrlCate(material)">
                    <template v-slot:label>
                      <span class="font-error">Required</span>
                    </template>
                    <template v-slot:append>
                      <v-icon> mdi-chevron-down </v-icon>
                    </template>
                  </v-autocomplete>

                  <div v-else class="text-start">
                    {{ material.mtrlCate }}
                  </div>
                </td>
                <!-- MATERIAL TYPE -->
                <td>
                  <v-autocomplete
                    v-if="editMode && material.canEdit === 'Y'"
                    dense
                    solo
                    hide-details
                    flat
                    outlined
                    :disabled="material.mtrlUse === 'Y'"
                    :items="$store.getters['company/getMtrlType']($store.getters.getCmpyUUID, material.mtrlCate)"
                    item-value="itemName"
                    item-text="itemName"
                    no-data-text="Please select the Material Category first."
                    v-model="material.mtrlType">
                    <template v-slot:label>
                      <span class="font-error">Required</span>
                    </template>
                    <template v-slot:append>
                      <v-icon> mdi-chevron-down </v-icon>
                    </template>
                  </v-autocomplete>
                  <div v-else class="text-start">
                    {{ material.mtrlType }}
                  </div>
                </td>
                <!-- ITEM NAME -->
                <td>
                  <v-text-field
                    v-if="editMode && material.canEdit === 'Y'"
                    solo
                    flat
                    dense
                    outlined
                    hide-details
                    :disabled="material.mtrlUse === 'Y'"
                    v-model="material.itemName"
                    background-color="white">
                    <template v-slot:label>
                      <span class="font-error">Required</span>
                    </template>
                  </v-text-field>

                  <div v-else class="text-start">
                    {{ material.itemName }}
                  </div>
                </td>
                <!-- COMPOSITION/ITEM DETAIL -->
                <td class="text-start">
                  <!-- fabric -->
                  <template v-if="material.mtrlCate === $store.getters['code/getMtrlCateFabricCode']">
                    <!-- 수정가능 -->
                    <div
                      v-if="editMode && material.canEdit === 'Y'"
                      class="pointer underline"
                      @click="editCompositionClicked(material)"
                      v-html="getMaterialRateText(material)" />

                    <!-- 수정불가 -->
                    <div v-else v-html="getMaterialRateText(material)" />
                  </template>

                  <!-- accessory, trim -->
                  <template v-else>
                    <!-- 수정가능 -->
                    <v-text-field
                      v-if="editMode && material.canEdit === 'Y'"
                      solo
                      flat
                      dense
                      outlined
                      hide-details
                      v-model="material.materialFabricRateSelectDtoList[0].mtrlName"
                      background-color="white">
                    </v-text-field>

                    <!-- 수정불가 -->
                    <div v-else>
                      {{ material.materialFabricRateSelectDtoList[0].mtrlName }}
                    </div>
                  </template>
                </td>
                <!-- POST PROCESSING -->
                <td>
                  <v-select
                    v-if="editMode && material.canEdit === 'Y'"
                    dense
                    solo
                    hide-details
                    flat
                    outlined
                    multiple
                    :items="$store.state.company.company[$store.getters.getCmpyUUID].postProcessing"
                    item-value="itemName"
                    item-text="itemName"
                    :label="material.mtrlCate !== $store.getters['code/getMtrlCateFabricCode'] ? '' : 'SELECT'"
                    v-model="material.postProcessingArr"
                    :disabled="material.mtrlCate !== $store.getters['code/getMtrlCateFabricCode']">
                    <template v-slot:append>
                      <v-icon> mdi-chevron-down </v-icon>
                    </template>
                    <template v-slot:selection="{ index }">
                      <span v-if="index === 0"> {{ material.postProcessingArr.length }} OPTIONS </span>
                    </template>
                  </v-select>

                  <div v-else>
                    <template v-if="hasValue(material.postProcessing)">
                      <span
                        v-for="pp in material.postProcessing.split('|$|')"
                        :key="`postProcessing ${materialIndex} ${pp}`">
                        {{ pp }}
                        <br />
                      </span>
                    </template>
                  </div>
                </td>
                <!-- DYEING -->
                <td>
                  <v-select
                    v-if="editMode && material.canEdit === 'Y'"
                    dense
                    solo
                    hide-details
                    flat
                    outlined
                    multiple
                    :items="$store.state.company.company[$store.getters.getCmpyUUID].dyeing"
                    item-value="itemName"
                    item-text="itemName"
                    :label="material.mtrlCate !== $store.getters['code/getMtrlCateFabricCode'] ? '' : 'SELECT'"
                    v-model="material.dyeingArr"
                    :disabled="material.mtrlCate !== $store.getters['code/getMtrlCateFabricCode']">
                    <template v-slot:append>
                      <v-icon> mdi-chevron-down </v-icon>
                    </template>
                    <template v-slot:selection="{ index }">
                      <span v-if="index === 0"> {{ material.dyeingArr.length }} OPTIONS </span>
                    </template>
                  </v-select>

                  <div v-else>
                    <template v-if="hasValue(material.dyeing)">
                      <span v-for="dyeing in material.dyeing.split('|$|')" :key="`dyeing ${materialIndex} ${dyeing}`">
                        {{ dyeing }}
                        <br />
                      </span>
                    </template>
                  </div>
                </td>
                <!-- PRINTING -->
                <td>
                  <v-select
                    v-if="editMode && material.canEdit === 'Y'"
                    dense
                    solo
                    hide-details
                    flat
                    outlined
                    multiple
                    :items="$store.state.company.company[$store.getters.getCmpyUUID].printing"
                    item-value="itemName"
                    item-text="itemName"
                    :label="material.mtrlCate !== $store.getters['code/getMtrlCateFabricCode'] ? '' : 'SELECT'"
                    v-model="material.printingArr"
                    :disabled="material.mtrlCate !== $store.getters['code/getMtrlCateFabricCode']">
                    <template v-slot:append>
                      <v-icon> mdi-chevron-down </v-icon>
                    </template>
                    <template v-slot:selection="{ index }">
                      <span v-if="index === 0"> {{ material.printingArr.length }} OPTIONS </span>
                    </template>
                  </v-select>

                  <div v-else>
                    <template v-if="hasValue(material.printing)">
                      <span
                        v-for="printing in material.printing.split('|$|')"
                        :key="`printing ${materialIndex} ${printing}`">
                        {{ printing }}
                        <br />
                      </span>
                    </template>
                  </div>
                </td>
                <!-- WEIGHT (UOM) -->
                <td>
                  <div class="d-flex" v-if="editMode && material.canEdit === 'Y'">
                    <v-text-field
                      dense
                      outlined
                      hide-details
                      type="number"
                      v-model.number="material.weight"
                      background-color="white"
                      :disabled="material.mtrlCate !== 'Fabric' && material.mtrlCate !== 'Trim'" />

                    <v-autocomplete
                      dense
                      solo
                      hide-details
                      flat
                      outlined
                      :items="$store.state.code.common.uomWeight"
                      item-value="itemName"
                      item-text="itemName"
                      v-model="material.weightUom"
                      :disabled="material.mtrlCate !== 'Fabric' && material.mtrlCate !== 'Trim'">
                      <template v-slot:append>
                        <v-icon> mdi-chevron-down </v-icon>
                      </template>
                    </v-autocomplete>
                  </div>

                  <div v-else>{{ getUOMText(material.weight, material.weightUom) }}</div>
                </td>
                <!-- ITEM SIZE (UOM) -->
                <td>
                  <div class="d-flex" v-if="editMode && material.canEdit === 'Y'">
                    <v-text-field
                      dense
                      outlined
                      hide-details
                      type="number"
                      v-model.number="material.itemSize"
                      background-color="white"
                      :disabled="material.mtrlCate !== 'Trim' && material.mtrlCate !== 'Accessory'" />

                    <v-autocomplete
                      dense
                      solo
                      hide-details
                      flat
                      outlined
                      :items="
                        material.mtrlCate === $store.getters['code/getMtrlCateFabricCode']
                          ? $store.state.code.common.uomFabric
                          : $store.state.code.common.uomOther
                      "
                      item-value="itemName"
                      item-text="itemName"
                      v-model="material.itemSizeUom"
                      :disabled="material.mtrlCate !== 'Trim' && material.mtrlCate !== 'Accessory'">
                      <template v-slot:append>
                        <v-icon> mdi-chevron-down </v-icon>
                      </template>
                    </v-autocomplete>
                  </div>

                  <div v-else>{{ getUOMText(material.itemSize, material.itemSizeUom) }}</div>
                </td>
                <!-- EDIT / MORE DATA -->
                <td class="td-btn">
                  <span v-if="!editMode"> {{ material.canEdit === "Y" ? "Y" : "N" }} </span>
                  <v-btn
                    v-else-if="
                      material.canEdit === 'Y' &&
                      hasValue(material.mtrlCate) &&
                      material.mtrlCate !== $store.getters['code/getMtrlCateAccessoryCode']
                    "
                    color="secondary"
                    outlined
                    fab
                    small
                    @click="material.expanded = !material.expanded">
                    <v-icon> {{ `mdi-chevron-${material.expanded ? "up" : "down"}` }} </v-icon>
                  </v-btn>
                </td>
                <!-- delete row -->
                <td v-if="editMode">
                  <v-btn
                    v-if="material.canEdit === 'Y'"
                    color="secondary"
                    outlined
                    fab
                    small
                    :disabled="material.mtrlUse === 'Y'"
                    @click="deleteMaterialClicked(material)">
                    <v-icon> mdi-minus </v-icon>
                  </v-btn>
                </td>
              </tr>

              <!-- #region expanded-row-->
              <tr :key="'material tr expanded ' + materialIndex" v-if="material.expanded" class="expanded-row">
                <td colspan="100">
                  <!-- #region Edit Row -->
                  <div class="edit" v-if="editMode">
                    <!-- #region optional-info -->
                    <div
                      class="optional-info"
                      v-if="material.mtrlCate !== $store.getters['code/getMtrlCateAccessoryCode']">
                      <h2 class="text-start">OPTIONAL INFORMATION</h2>

                      <v-divider />

                      <table>
                        <thead>
                          <tr>
                            <th rowspan="2">STRUCTURE</th>
                            <th colspan="2">YARN SIZE</th>
                            <th colspan="2">CONSTRUCTION</th>
                            <th colspan="2">SHRINGKAGE</th>
                          </tr>
                          <tr>
                            <th>WRAP</th>
                            <th>WEFT</th>
                            <th>EPI</th>
                            <th>PPI</th>
                            <th>+</th>
                            <th>-</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <v-text-field
                                dense
                                outlined
                                hide-details
                                background-color="white"
                                v-model="material.materialOptionSelectDto.structure" />
                            </td>
                            <td>
                              <v-text-field
                                dense
                                outlined
                                hide-details
                                background-color="white"
                                v-model="material.materialOptionSelectDto.yarnSizeWrap" />
                            </td>
                            <td>
                              <v-text-field
                                dense
                                outlined
                                hide-details
                                background-color="white"
                                v-model="material.materialOptionSelectDto.yarnSizeWeft" />
                            </td>
                            <td>
                              <!-- <v-text-field
                                dense
                                outlined
                                hide-details
                                background-color="white"
                                type="number"
                                v-model="material.materialOptionSelectDto.constructionEpi" /> -->
                              <input-number v-model="material.materialOptionSelectDto.constructionEpi" />
                            </td>
                            <td>
                              <!-- <v-text-field
                                dense
                                outlined
                                hide-details
                                background-color="white"
                                type="number"
                                v-model="material.materialOptionSelectDto.constructionPpi" /> -->
                              <input-number v-model="material.materialOptionSelectDto.constructionPpi" />
                            </td>
                            <td>
                              <!-- <v-text-field
                                dense
                                outlined
                                hide-details
                                background-color="white"
                                type="number"
                                v-model="material.materialOptionSelectDto.shrinkagePlus" /> -->
                              <input-number v-model="material.materialOptionSelectDto.shrinkagePlus" />
                            </td>
                            <td>
                              <!-- <v-text-field
                                dense
                                outlined
                                hide-details
                                background-color="white"
                                type="number"
                                v-model="material.materialOptionSelectDto.shrinkageMinus" /> -->
                              <input-number v-model="material.materialOptionSelectDto.shrinkageMinus" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- #endregion -->

                    <!-- #region tag-info -->
                    <div v-if="material.mtrlCate === $store.getters['code/getMtrlCateFabricCode']" class="tag-info">
                      <h2>TAG INFORMATION</h2>

                      <v-divider />

                      <div class="tag-info-tables d-flex">
                        <div class="selected-tag-table">
                          <table>
                            <thead>
                              <tr>
                                <th>Group</th>
                                <th>TAG DATA</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="tagType in materialTagTypes"
                                :key="`selected tagType ${tagType}`"
                                @click="material.clickedTag = tagType"
                                :class="`pointer ${material.clickedTag === tagType ? 'active' : ''}`">
                                <td>{{ tagType }}</td>
                                <td>
                                  <v-chip
                                    v-for="tag in material.tagInformation[tagType]"
                                    :key="`selected ${tagType} ${tag}`"
                                    label
                                    link
                                    @click.stop="
                                      material.tagInformation[tagType].splice(
                                        material.tagInformation[tagType].indexOf(tag),
                                        1,
                                      )
                                    ">
                                    {{ tag }}
                                  </v-chip>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div class="tag-info-arrow align-self-center"><v-icon x-large>mdi-arrow-left-bold</v-icon></div>

                        <div class="select-tag-list-table">
                          <table>
                            <thead>
                              <tr>
                                <th>{{ material.clickedTag }} TAG</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <v-chip
                                  v-for="code in $store.state.company.company[$store.getters.getCmpyUUID][
                                    toCamelCase(material.clickedTag)
                                  ]"
                                  :key="`${material.clickedTag} ${code.itemName}`"
                                  label
                                  link
                                  :disabled="material.tagInformation[material.clickedTag].includes(code.itemName)"
                                  @click="material.tagInformation[material.clickedTag].push(code.itemName)">
                                  {{ code.itemName }}
                                </v-chip>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!-- #endregion -->
                  </div>
                  <!-- #endregion -->

                  <!-- #region View Row -->
                  <div class="view" v-else>
                    <!-- #region optional-info -->
                    <div
                      class="optional-info"
                      v-if="material.mtrlCate !== $store.getters['code/getMtrlCateAccessoryCode']">
                      <h2 class="text-start">OPTIONAL INFORMATION</h2>

                      <v-divider />

                      <!-- #region options -->
                      <div class="d-flex options">
                        <div class="option">
                          <div class="types">
                            <p>STRUCTURE</p>
                          </div>
                          <div class="contents">
                            <p>{{ material.materialOptionSelectDto.structure }}</p>
                          </div>
                        </div>

                        <div class="option">
                          <div class="types">
                            <p>YARN SIZE WRAP</p>
                            <p>WEFT</p>
                          </div>
                          <div class="contents">
                            <p>{{ material.materialOptionSelectDto.yarnSizeWrap }}</p>
                            <p>{{ material.materialOptionSelectDto.yarnSizeWeft }}</p>
                          </div>
                        </div>

                        <div class="option">
                          <div class="types">
                            <p>Construction EPI</p>
                            <p>PPI</p>
                          </div>
                          <div class="contents">
                            <p>{{ material.materialOptionSelectDto.constructionEpi }}</p>
                            <p>{{ material.materialOptionSelectDto.constructionPpi }}</p>
                          </div>
                        </div>

                        <div class="option">
                          <div class="types">
                            <p>SHRINKAGE</p>
                          </div>
                          <div class="contents">
                            <p>+ {{ material.materialOptionSelectDto.shrinkagePlus }}</p>
                            <p>- {{ material.materialOptionSelectDto.shrinkageMinus }}</p>
                          </div>
                        </div>
                      </div>
                      <!-- #endregion -->

                      <!-- #region tags -->
                      <div
                        v-if="material.mtrlCate === $store.getters['code/getMtrlCateFabricCode']"
                        class="d-flex tags">
                        <div class="tag" v-for="tagType in materialTagTypes" :key="`VIEW TAG TYPE ${tagType}`">
                          <div class="type">{{ tagType }}</div>
                          <div class="contents">
                            <div
                              v-for="(tag, index) in material.tagInformation[tagType]"
                              :key="`VIEW ${tagType} ${index}`">
                              <v-chip label>
                                {{ tag }}
                              </v-chip>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- #endregion -->
                    </div>
                    <!-- #endregion -->

                    <!-- cost record history -->
                    <cost-record-history :material="material" />
                  </div>
                  <!-- #endregion -->
                </td>
              </tr>
              <!-- #endregion -->
            </template>
          </tbody>
        </table>

        <!-- pagination을 editMode일때 안보여주는 이유 : -->
        <!-- editMode일 때, add Item을 했다하여 현재 아이템 중 1개를 뒤페이지로 보내지 않고, -->
        <!-- remove Item을 했다하여 아이템을 1개 당겨오지도 않음. -->
        <!-- page 이동 시, 새로운 item을 받아오면 기존 수정한 내용도 날아감. -->
        <div class="text-center pt-2" v-if="!editMode && pageCount > 0">
          <v-pagination v-model="page" :length="pageCount" color="secondary" :total-visible="$pagePV"></v-pagination>
        </div>
      </div>
      <!-- #endregion -->
    </v-card>

    <confirm :show.sync="deleteConfirmShow" :confirmButton="{ text: 'OK' }" @confirm="deleteMaterial">
      Are you trying to delete the material? <br />
      This action will not recover material data.
    </confirm>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <set-composition
      :show.sync="setCompositionShow"
      :items="setCompositionTarget.materialFabricRateSelectDtoList || []"
      @setComposition="setComposit" />
    <create-company :show.sync="createCompanyShow" :spplrFixed="true" @regist="registSpplr" />
  </div>
</template>

<script>
import codeHandler from "@/utils/codeHandler";
import CreateCompany from "@/components/community/CreateCompany.vue";
import SetComposition from "@/components/company/material/SetComposition.vue";
import CostRecordHistory from "@/components/company/material/CostRecordHistory.vue";
import CompanyInfo from "@/components/common/popover/CompanyInfo.vue";
import { hasValue, isNumber, _deepCopy, toCamelCase } from "@/utils/util";
import Confirm from "@/components/common/cards/Confirm.vue";
import InputNumber from "@/components/common/inputs/InputNumber.vue";

export default {
  components: {
    CreateCompany,
    SetComposition,
    CostRecordHistory,
    CompanyInfo,
    Confirm,
    InputNumber,
  },
  data() {
    return {
      editMode: false,
      editValue: "ALL",
      filterColumn: "ALL",
      searchText: "",
      backupMaterials: [],
      materials: [],
      page: 1,
      totalCnt: 0,
      itemsPerPage: 10,
      loading: false,
      saveLoading: false,
      setCompositionShow: false,
      setCompositionTarget: {},
      spplrList: [],
      createCompanyShow: false,
      changeSpplrTarget: null,
      deleteConfirmShow: false,
      deleteConfirmTarget: null,
      breadcrumbs: [
        {
          text: "Material Management",
          disabled: true,
        },
        {
          text: "Material List",
          disabled: true,
        },
      ],
      searchColumns: [
        { text: "All", value: "ALL" },
        { text: "SUPPLIER", value: "spplrName" },
        { text: "VGO ID", value: "vgoId" },
        { text: "MILL/ITEM NO", value: "itemNo" },
        { text: "RD NO", value: "rdNo" },
        { text: "COO", value: "coo" },
        { text: "MATERIAL CATEGORY", value: "mtrlCate" },
        { text: "MATERIAL TYPE", value: "mtrlType" },
        { text: "ITEM NAME", value: "itemName" },
        { text: "COMPOSITION / ITEM DETAIL", value: "mtrlRate" },
        { text: "POST PROCESSING", value: "postProcessing" },
        { text: "DYEING", value: "dyeing" },
        { text: "PRINTING", value: "printing" },
        { text: "WEIGHT", value: "weight" },
        { text: "ITEM SIZE", value: "itemSize" },
      ],
      editModes: [
        {
          text: "All",
          value: "ALL",
        },
        {
          text: "Editable",
          value: "Y",
        },
        {
          text: "Non Editable",
          value: "N",
        },
      ],
    };
  },
  methods: {
    getMaterials(changeFilters) {
      this.loading = true;
      if (changeFilters) this.page = 1;

      this.$http
        .get("/material", {
          data: {
            cmpyUUID: this.$store.getters.getCmpyUUID,
            canEditValue: this.editValue,
            filterColumn: this.filterColumn,
            searchText: this.searchText,
            currentPageNo: this.page,
          },
        })
        .then(res => {
          if (res.data.status === 200) {
            const mtrls = res.data.data.map(m => {
              return this.materialSetting(m);
            });
            this.materials = mtrls;
            this.totalCnt = res.data.data[0]?.totalCnt;
          } else codeHandler(res.data);
        })
        .finally(() => (this.loading = false));
    },
    getSuppliers() {
      this.$http.get("company/list").then(res => {
        if (res.data.status === 200)
          this.spplrList = [
            { cmpyUUID: "ADD_SPPLR", name: "ADD A NEW SUPPLIER COMPANY" },
            { divider: true },
            ...res.data.data,
          ];
        else codeHandler(res.data);
      });
    },
    changeEditMode(val, notChange) {
      if (val === true) {
        this.backupMaterials = _deepCopy(this.materials);
      } else {
        if (!notChange) this.materials = _deepCopy(this.backupMaterials);
        else this.getMaterials();
      }

      this.materials.forEach(m => (m.expanded = false));
      this.totalCnt = this.materials[0]?.totalCnt;

      this.editMode = val;
    },
    materialSetting(material) {
      const newMaterial = {
        ...material,
        menu: false,
        checked: false,
        expanded: false,
        postProcessingArr: hasValue(material.postProcessing) ? material.postProcessing.split("|$|") : [],
        dyeingArr: hasValue(material.dyeing) ? material.dyeing.split("|$|") : [],
        printingArr: hasValue(material.printing) ? material.printing.split("|$|") : [],
        clickedTag: "USAGE TYPE",
        tagInformation: {
          "USAGE TYPE": [],
          APPLICATION: [],
          SUSTAINABILITY: [],
          CHARACTERISTIC: [],
          PATTERN: [],
          FUNCTION: [],
          PERFORMANCE: [],
          STRETCH: [],
        },
      };

      //#region contactCall concat
      if (material.companySelectDto) {
        material.companySelectDto.contactCall = hasValue(material.companySelectDto.contactCallCountry)
          ? `${material.companySelectDto.contactCallCountry} ${material.companySelectDto.contactCall}`
          : material.companySelectDto.contactCall;
      }
      //#endregion

      //#region COMPOSITION / ITEM DETAIL이 없을 경우 Default 값 설정
      if (
        !Array.isArray(newMaterial.materialFabricRateSelectDtoList) ||
        newMaterial.materialFabricRateSelectDtoList.length < 1
      ) {
        newMaterial.materialFabricRateSelectDtoList = [];
        newMaterial.materialFabricRateSelectDtoList[0] = { mtrlRate: "", mtrlName: "" };
      }
      //#endregion

      //#region Option Default 설정
      if (!newMaterial.materialOptionSelectDto || typeof newMaterial.materialOptionSelectDto !== "object") {
        newMaterial.materialOptionSelectDto = {};
      }
      [
        "structure",
        "yarnSizeWrap",
        "yarnSizeWeft",
        "constructionEpi",
        "constructionPpi",
        "shrinkagePlus",
        "shrinkageMinus",
      ].forEach(op => {
        if (!newMaterial.materialOptionSelectDto[op]) newMaterial.materialOptionSelectDto[op] = "";
      });
      //#endregion

      //#region Tag 분류작업
      if (
        Array.isArray(newMaterial.materialOptionTagSelectDtoList) &&
        newMaterial.materialOptionTagSelectDtoList.length > 0
      ) {
        newMaterial.materialOptionTagSelectDtoList.forEach(tag => {
          if (newMaterial.tagInformation[tag.tagType]) newMaterial.tagInformation[tag.tagType].push(tag.tagName);
        });
      }
      //#endregion

      return newMaterial;
    },
    getMaterialRateText(material) {
      if (
        //값이 없어 최초 세팅값인 경우
        material.materialFabricRateSelectDtoList.length === 1 &&
        material.materialFabricRateSelectDtoList[0].mtrlName === "" &&
        material.materialFabricRateSelectDtoList[0].mtrlRate === ""
      ) {
        if (material.canEdit === "Y" && !hasValue(material.mtrlUUID))
          return "<span class='font-error pointer'>SET COMPOSITION</span>";
        return "";
      }

      let text = "";

      material.materialFabricRateSelectDtoList?.forEach((m, i) => {
        text += `${m.mtrlName}: ${m.mtrlRate}%`;
        if (i !== material.materialFabricRateSelectDtoList.length - 1) text += "<br/>";
      });
      return text;
    },
    changeSpplrUUID(material) {
      if (material.spplrUUID === "ADD_SPPLR") {
        //tempSpplrUUIDCnt는, 계속 undefined로만 바꾸면 change를 인식을 못해서 생성
        if (!hasValue(material.tempSpplrUUIDCnt)) material.tempSpplrUUIDCnt = 0;
        material.tempSpplrUUIDCnt++;
        material.spplrUUID = material.tempSpplrUUIDCnt % 2 === 0 ? undefined : null;
        this.createCompanyShow = true;
        this.changeSpplrTarget = material;
      }
    },
    getUOMText(value, uom = "") {
      if (hasValue(value) && hasValue(uom)) return `${value} (${uom})`;
      else if (hasValue(uom.trim())) return `${value || "0"} ${uom}`;
      return "";
    },
    editCompositionClicked(material) {
      this.setCompositionTarget = material;
      this.setCompositionShow = true;
    },
    setComposit(composition) {
      this.setCompositionTarget.materialFabricRateSelectDtoList = composition;
    },
    addMaterial() {
      this.materials.unshift(
        this.materialSetting({
          canEdit: "Y",
          spplrName: "",
          spplrUUID: "",
          vgoId: "",
          itemNo: "",
          rdNo: "",
          coo: "",
          mtrlCate: "",
          mtrlType: "",
          itemName: "",
          postProcessing: "",
          dyeing: "",
          printing: "",
          weight: "",
          weightUom: "",
          itemSize: "",
          itemSizeUom: "",
          materialCostHistoryDtoList: [],
          clickedTag: "USAGE TYPE",
        }),
      );
      this.totalCnt = this.totalCnt + 1;
    },
    registSpplr(company) {
      this.spplrList.push(company);
      this.changeSpplrTarget.spplrUUID = company.cmpyUUID;
    },
    changeMtrlCate(material) {
      material.materialFabricRateSelectDtoList = [{ mtrlName: "", mtrlRate: "" }];
      switch (material.mtrlCate) {
        case "Fabric":
          material.itemSize = "";
          material.itemSizeUom = "";
          break;
        case "Accessory":
          material.weight = "";
          material.weightUom = "";
          break;
        default:
          break;
      }
    },
    deleteMaterialClicked(material) {
      this.deleteConfirmShow = true;
      this.deleteConfirmTarget = material;
    },
    deleteMaterial() {
      if (!hasValue(this.deleteConfirmTarget)) return;
      const material = this.deleteConfirmTarget;

      if (!hasValue(material.mtrlUUID)) {
        this.materials.splice(
          this.materials.findIndex(m => m === material),
          1,
        );
      } else {
        this.$http
          .patch("/material", { cmpyUUID: this.$store.getters.getCmpyUUID, mtrlUUID: [material.mtrlUUID] })
          .then(res => {
            if (res.data.status === 200) {
              this.materials.splice(
                this.materials.findIndex(m => m === material),
                1,
              );
            } else codeHandler(res.data);
          });
      }
    },
    save() {
      let breakReason = "";
      const materialInsertRequestDtoList = [];
      for (let materialIndex = 0; materialIndex < this.materials.length; materialIndex++) {
        const material = this.materials[materialIndex];

        //#region 변경사항 체크
        let changed = false;
        if (hasValue(material.mtrlUUID)) {
          const orgMaterial = this.backupMaterials.find(m => m.mtrlUUID === material.mtrlUUID);
          if (!orgMaterial) continue;

          changed =
            orgMaterial.itemNo !== material.itemNo ||
            orgMaterial.rdNo !== material.rdNo ||
            orgMaterial.coo !== material.coo ||
            orgMaterial.mtrlType !== material.mtrlType ||
            orgMaterial.itemName !== material.itemName ||
            orgMaterial.postProcessing !== material.postProcessingArr.join("|$|") ||
            orgMaterial.dyeing !== material.dyeingArr.join("|$|") ||
            orgMaterial.printing !== material.printingArr.join("|$|") ||
            orgMaterial.weight !== material.weight ||
            orgMaterial.weightUom !== material.weightUom ||
            orgMaterial.itemSize !== material.itemSize ||
            orgMaterial.itemSizeUom !== material.itemSizeUom ||
            orgMaterial.materialOptionSelectDto.structure !== material.materialOptionSelectDto.structure ||
            orgMaterial.materialOptionSelectDto.yarnSizeWrap !== material.materialOptionSelectDto.yarnSizeWrap ||
            orgMaterial.materialOptionSelectDto.yarnSizeWeft !== material.materialOptionSelectDto.yarnSizeWeft ||
            orgMaterial.materialOptionSelectDto.constructionEpi !== material.materialOptionSelectDto.constructionEpi ||
            orgMaterial.materialOptionSelectDto.constructionPpi !== material.materialOptionSelectDto.constructionPpi ||
            orgMaterial.materialOptionSelectDto.shrinkagePlus !== material.materialOptionSelectDto.shrinkagePlus ||
            orgMaterial.materialOptionSelectDto.shrinkageMinus !== material.materialOptionSelectDto.shrinkageMinus ||
            orgMaterial.materialFabricRateSelectDtoList.map(m => m.mtrlName + "" + m.mtrlRate).join("") !==
              material.materialFabricRateSelectDtoList.map(m => m.mtrlName + "" + m.mtrlRate).join("") ||
            Object.keys(orgMaterial.tagInformation)
              .map(key => orgMaterial.tagInformation[key].join(""))
              .join("") !==
              Object.keys(material.tagInformation)
                .map(key => material.tagInformation[key].join(""))
                .join("");
        } else {
          //새로 생성된 것은 무조건 서버 전송
          changed = true;
        }
        //#endregion

        //#region 파라미터 생성
        if (changed) {
          //#region 필수값 체크
          if (
            ![material.spplrUUID, material.mtrlCate, material.coo, material.mtrlType, material.itemName].every(hasValue)
          ) {
            breakReason = "required";
            break;
          }

          if (!this.hasMtrlRate(material)) {
            breakReason = "required";
            break;
          }

          if (
            ![
              material.weight,
              material.itemSize,
              material.constructionEpi,
              material.constructionPpi,
              material.shrinkagePlus,
              material.shrinkageMinus,
            ].every(n => isNumber(n, true))
          ) {
            breakReason = "number";
            break;
          }
          //#endregion

          const materialInsertDto = {
            mtrlUUID: material.mtrlUUID,
            spplrUUID: material.spplrUUID,
            itemNo: material.itemNo,
            rdNo: material.rdNo,
            coo: material.coo,
            mtrlCate: material.mtrlCate,
            mtrlType: material.mtrlType,
            itemName: material.itemName,
            postProcessing: material.postProcessingArr.join("|$|"),
            dyeing: material.dyeingArr.join("|$|"),
            printing: material.printingArr.join("|$|"),
            weight: material.weight,
            weightUom: material.weightUom,
            itemSize: material.itemSize,
            itemSizeUom: material.itemSizeUom,
          };

          const materialFabricRateInsertDtoList = material.materialFabricRateSelectDtoList;

          const materialOptionInsertDto = material.materialOptionSelectDto;

          const materialOptionTagInsertDtoList = Object.keys(material.tagInformation).map(key => ({
            tagName: material.tagInformation[key],
            tagType: key,
          }));

          materialInsertRequestDtoList.push({
            materialInsertDto,
            materialFabricRateInsertDtoList,
            materialOptionInsertDto,
            materialOptionTagInsertDtoList,
          });
        }
        //#endregion
      }

      if (breakReason === "required") return this.$toast.warning("Please enter all the required items");
      if (breakReason === "number") return this.$toast.warning("Please only be entered with numbers");

      this.saveLoading = true;
      this.$http
        .post("/material", { materialInsertRequestDtoList, cmpyUUID: this.$store.getters.getCmpyUUID })
        .then(res => {
          if (res.data.status === 200) {
            this.$toast.success("Successfully saved");
            this.changeEditMode(false, true);
          } else codeHandler(res.data);
        })
        .finally(() => (this.saveLoading = false));
    },
    hasMtrlRate(material) {
      if (!hasValue(material)) return false;

      if (material.mtrlCate === this.$store.getters["code/getMtrlCateFabricCode"])
        //fabric일 경우 필수
        return (
          hasValue(material.materialFabricRateSelectDtoList) &&
          hasValue(material.materialFabricRateSelectDtoList[0]?.mtrlName) &&
          hasValue(material.materialFabricRateSelectDtoList[0]?.mtrlRate)
        );
      //아닐경우 빈칸 허용
      else return true;
    },
    hasValue,
    toCamelCase,
  },
  computed: {
    pageCount() {
      return Math.ceil((this.totalCnt || 0) / this.itemsPerPage);
    },
    materialTagTypes() {
      return this.$store.getters["code/getMaterialTagTypes"];
    },
  },
  watch: {
    page() {
      this.getMaterials();
    },
  },
  created() {
    this.getMaterials();
    this.getSuppliers();
  },
};
</script>

<style></style>
