<template>
  <v-dialog v-model="dialogShow" width="1000px" content-class="suggestion-price-modal customEditModal company">
    <v-card class="pa-5">
      <h1 v-text="dialogTitle" />

      <div class="mt-10 content">
        <div class="guide" v-if="!isView" v-html="guideHTML" />

        <div class="custom-table">
          <table>
            <thead>
              <tr>
                <th
                  v-for="info in suggestionInfo"
                  :key="'info' + info.key"
                  :class="`${info.essential ? 'essential' : ''}`">
                  {{ info.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-for="info in suggestionInfo" :key="'input' + info.key">
                  <div v-if="isView">
                    {{ $props.priceInfo[info.key] }}
                  </div>

                  <v-autocomplete
                    v-else-if="info.selectItems !== undefined"
                    dense
                    solo
                    hide-details
                    flat
                    outlined
                    :items="info.selectItems"
                    item-value="itemName"
                    item-text="itemName"
                    v-model="$data[info.key]">
                    <template v-slot:append>
                      <v-icon> mdi-chevron-down </v-icon>
                    </template>
                  </v-autocomplete>

                  <v-text-field
                    v-else
                    dense
                    outlined
                    hide-details
                    background-color="white"
                    :disabled="info.disabled"
                    v-model="$data[info.key]"
                    :rules="[info.essential ? rules.required : rules.none]"
                    :type="info.isNum ? 'number' : 'text'" />

                  <span v-if="info.afterText" v-text="info.afterText" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="message">
          <div v-if="isView">{{ $props.priceInfo.message }}</div>
          <v-textarea outlined placeholder="Leave your messages here" v-model="message" v-if="isSuggestion" />
        </div>

        <div class="actions" v-if="!isView">
          <v-btn color="secondary" dark :elevation="0" @click="save"> SAVE </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { hasValue, isNumber } from "@/utils/util";
import codeHandler from "@/utils/codeHandler";
export default {
  props: ["mode", "show", "mtrlUUID", "parentCostHistId", "priceInfo"],
  data() {
    return {
      rules: {
        none: () => true,
        required: value => !!value || "Required",
      },
      suggestionInfo: [
        {
          text: "CURRENCY",
          key: "currency",
          essential: true,
          selectItems: this.$store.state.code.common.currency,
        },
        {
          text: "UNIT PRICE",
          key: "unitPrice",
          essential: true,
          isNum: true,
        },
        {
          text: "UOM",
          key: "uom",
          essential: true,
          selectItems: this.$store.state.code.common.uomFabric,
        },
        {
          text: "FW",
          key: "fw",
          essential: false,
          afterText: "inch",
          isNum: true,
        },
        {
          text: "CW",
          key: "cw",
          essential: false,
          afterText: "inch",
        },
        {
          text: "MOQ",
          key: "moq",
          essential: true,
          isNum: true,
        },
        {
          text: "MCQ",
          key: "mcq",
          essential: true,
          isNum: true,
        },
        {
          text: "LEAD TIME",
          key: "leadtime",
          essential: true,
          afterText: "DAYS",
          isNum: true,
        },
      ],
      currency: undefined,
      unitPrice: "",
      uom: undefined,
      fw: "",
      cw: "",
      moq: "",
      mcq: "",
      leadtime: "",
      message: "",
      isSuggestion: false,
      isView: false,
    };
  },
  methods: {
    save() {
      if (
        !this.suggestionInfo
          .filter(i => i.essential)
          .map(i => this[i.key])
          .every(hasValue)
      )
        return this.$toast.warning("Please enter all the required items.");

      if (
        !this.suggestionInfo
          .filter(i => i.isNum)
          .map(i => this[i.key])
          .every(isNumber)
      )
        return this.$toast.warning("Please only be entered with numbers.");

      const params = {
        mtrlUUID: this.mtrlUUID,
        cmpyUUID: this.$store.getters.getCmpyUUID,
      };

      if (this.isSuggestion) {
        params.parentCostHistId = this.parentCostHistId;
        params.message = this.message;
      }

      this.suggestionInfo.forEach(si => (params[si.key] = this[si.key]));

      this.$http.post(this.isSuggestion ? "/material/cost/suggestion" : "/material/cost/official", params).then(res => {
        if (res.data.status === 200) {
          params.inputLocation = this.isSuggestion ? "S" : "O";
          params.workedBy = this.$store.state.user.name;
          params.costHistId = res.data.data.costHistId;
          params.when = res.data.data.localWhen;
          params.cmpyUUID = undefined;

          this.$emit("save", params);
          this.dialogShow = false;
        } else codeHandler(res.data);
      });
    },
    setUpData() {
      this.isSuggestion = this.mode === "suggestion";
      this.isView = this.mode === "view";
    },
  },
  computed: {
    dialogShow: {
      get() {
        return this.show;
      },
      set(value) {
        Object.assign(this.$data, this.$options.data.apply(this));
        this.setUpData();
        this.$emit("update:show", value);
      },
    },
    guideHTML() {
      return this.isSuggestion
        ? "The proposed price cannot be seen by anyone other than the person who received the offer.<br />If everyone wants to see the price, please disclose the OFFICIAL PRICE through INPUT PRICE."
        : "The price entered as an official price will be disclosed to all buyers";
    },
    dialogTitle() {
      return this.isView || this.isSuggestion ? "SUGGESTION PRICE" : "INPUT OFFICIAL PRICE";
    },
  },
  created() {
    this.setUpData();
  },
};
</script>

<style></style>
